

.checkbox-wrap,
.listcheckbox-wrap {
    .nf-field-label,
    .nf-field-element {
        label {
            position: relative;
            margin-left: 30px;
            &::after {
                border: 1px solid;
                content: "";
                width: 18px;
                height: 18px;
                position: absolute;
                left: -28px;
                bottom: 0;
            }
            &::before {
                color: #FF0000;
                content: "\f00c";
                font-family: FontAwesome;
                font-size: 24px;
                position: absolute;
                bottom: 0;
                opacity: 0;
                z-index: 2;
            }
        }
    }
}
.checkbox-container {
    .nf-field-element {
        input {
            display: inherit;
            opacity: 0;
        }
    }
    &.label-above,
    &.label-below {
        // .nf-field-element {
        //     input {
        //         display: none;
        //     }
        // }
    }
    &.label-above {
        label {
            height: 50px;
            margin: 0;
            display: block;
            &::before {
                left: -2px;
                bottom: -16px;
            }
            &::after {
                left: 0;
                bottom: -16px;
            }
        }
    }
    &.label-below {
        label {
            margin: 0;
            display: block;
            margin-top: 20px;
            &::before {
                left: -2px;
                bottom: auto;
                top: -27px;
            }
            &::after {
                left: 0;
                bottom: auto;
                top: -25px;
            }
        }
    }
    &.label-left {
        .nf-field-label {
            width: 38%;
            padding-right: 5%;
        }
        .nf-field-element {
            width: 58%;
        }
        label {
            margin: 0;
            display: block;
            &::before {
                left: auto;
                top: -2px;
                right: -36px;
            }
            &::after {
                left: auto;
                top: 0;
                right: -31px;
            }
        }
    }
    &.label-right {
        .nf-field-label {
            padding: 0;
            order: 10;
        }
        .nf-field-element {
            order: 5;
            display: inline;
            position: absolute;
            opacity: 0;
        }
        label {
            margin: 0 0 0 30px;
            display: block;
            &::before {
                left: -30px;
                top: 0;
            }
            &::after {
                left: -28px;
                top: 0;
            }
        }
        .field-wrap {
            display: flex;
            > div {
                width: 100%;
            }
        }
        label {
            &::after {
                // Commenting this line out to deal with checkbox list issues.
                // Leaving it in place because it may need to be re-visited in the future.
                //top: 2px !important;
            }
        }
    }
    &.label-hidden {
        label {
            height: 0;
            margin: 0;
            display: block;
            &::after {
                left: 0;
                bottom: 0;
                visibility: visible;
            }
            &.nf-checked-label {
                &::before {
                    left: -2px;
                    bottom: 0;
                    visibility: visible;
                }
            }
        }
    }
}
.listcheckbox-container {
    .nf-field-label {
        label {
            &::after {
                background: transparent !important;
                border: 0;
            }
        }
    }
    .nf-field-element {
        input {
            display: inherit;
            opacity: 0;
        }
        label {
            margin-left: 30px !important;
            &::before {
                top: 1px;
                left: -30px;
                bottom: auto;
            }
            &::after {
                top: 3px;
                bottom: auto;
            }
        }
    }
    &.label-above,
    &.label-below,
    &.label-right {
        .nf-field-label {
            label {
                margin: 0;
                &::before {
                    left: -30px;
                }
            }
        }
    }
}
