.nf-help {
    background: none;
}
.nf-field-description {
    font-size: 14px;
}
.nf-form-content {
    padding: 5px;
    margin: 0 auto;
    label {
        font-size: 18px;
        line-height: 20px;
    }
    .ninja-forms-field,
    .listselect-wrap .nf-field-element div {
        font-size: 16px;
        margin: 0;
        padding: 12px;
    }
    input.ninja-forms-field,
    select.ninja-forms-field:not([multiple]),
    .listselect-wrap .nf-field-element div {
        height: 50px;
    }
    textarea.ninja-forms-field {
        height: 200px;
    }
    hr.ninja-forms-field {
        padding: 0;
    }
    input[type="radio"].ninja-forms-field,
    input[type="checkbox"].ninja-forms-field  {
        height: auto;
    }
    .listradio-wrap,
    .listcheckbox-wrap {
        li {
            margin-bottom: 12px !important;
            line-height: 0;
        }
    }
    .nf-product-field {
        &.ninja-forms-field {
            // background: transparent;
            // border: 0;
            // padding: 0;
        }
        span {
            padding-right: 16px;
        }
        .nf-field-qty {
            font-size: 16px;
            padding: 5px;
            width: 80px !important;
        }
    }
    .listselect-wrap,
    .liststate-wrap,
    .listcountry-wrap {
        select {
            //height: 50px;
        }
    }
    .nf-static {
        background: transparent;
        border: 0;
        font-weight: bold;
        padding: 0;
    }
    .label-above,
    .label-below,
    .label-hidden {
        .nf-field-label {
            margin-bottom: 10px;
            padding: 0;
        }
    }
    .label-below {
        .nf-field-label {
            margin-bottom: 0;
            margin-top: 10px;
        }
    }
    .label-right {
        .nf-field-label {
            margin: 0;
        }
        > input,
        textarea,
        select,
        .nf-product-field,
        .starrating,
        .nf-static,
        .pikaday__container {
            // margin-right: 1%;
        }
        > input[type=radio],
        input[type=checkbox] {
            margin-right: 1%;
        }
        ul {
            margin-right: 1%;
        }
    }
    .label-left {
        // > label {
        //     margin: 0;
        // }
        // > input,
        // textarea,
        // select,
        // .pikaday__container {
        //     // margin-left: 1%;
        // }
        // > input[type=radio],
        // input[type=checkbox] {
        //     margin-left: 1%;
        // }
        // ul {
        //     margin-left: 15px;
        // }
    }
}
